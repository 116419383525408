function fullPageSizeCheck(fullPageActivated) {
  if ($(window).width() > 1200) {
    if (fullPageActivated == 0) {
      $("#fullpage").fullpage({
        autoScrolling: true,
        verticalCentered: false,
        fixedElements: ".fixed-header, .fixed-menu",
        animateAnchor: false,
        onLeave: function (origin, destination, direction) {
          $(".fixed-menu ul li a").removeClass("active");
          $(
            `.fixed-menu ul li a[data-section-index=${destination.index}]`
          ).addClass("active");
        }
      });

      fullPageActivated = 1;
    }
  }
}

function restaurantItemsWidth() {
  const itemWidth = $(".section-03 .restaurant-item").width();
  $(".section-03 .restaurant-item .inner").css("flex", `0 0 ${itemWidth}px`);
}

function eventItemsWidth() {
  const itemWidth = $(".section-06 .evenement-button").width();
  $(".section-06 .evenement-button .inner").css("flex", `0 0 ${itemWidth}px`);
}

$(window).resize(function (event) {
  restaurantItemsWidth();
  eventItemsWidth();
  fullPageSizeCheck();
});

$(document).ready(function () {
  /**
   *
   * DESKTOP FUNCTIONS
   *
   */

  /*======  INITIALISATION  ======*/

  /*----------  FULLPAGE JS SETUP  ----------*/

  var fullPageActivated = 0;
  fullPageSizeCheck(fullPageActivated);

  $("video").each((i, v) => v.play());

  /*----------  INITIAL WIDTHS  ----------*/

  restaurantItemsWidth();
  eventItemsWidth();

  /*----------  INITIAL SECTION 02 SHOW / HIDE  ----------*/

  $(
    '.media-breakpoint .section-02 .tekst-blok[data-infoitem-target!="default"]'
  ).hide();

  /*======  ACTIONS  ======*/

  /*----------  MENU TOGGLE  ----------*/

  $(".menu-toggle").click(function (event) {
    event.preventDefault();
    $(".fixed-menu").toggleClass("active");
  });

  /*----------  SECTION 02 // TRANSITION INFOITEMS  ----------*/

  $(".infoitem-link").click(function (event) {
    event.preventDefault();
    const target = $(this).data("infoitem-target");

    $(".media-breakpoint .section-02 .links a").removeClass("active");
    $(this).addClass("active");

    $(".media-breakpoint .section-02 .tekst-blok").hide();
    $(
      `.media-breakpoint .section-02 .tekst-blok[data-infoitem-target=${target}]`
    ).fadeIn();
  });

  $(".btn-back").click(function (event) {
    event.preventDefault();
    const target = "default";

    $(".media-breakpoint .section-02 .links a").removeClass("active");
    $(this).addClass("active");

    $(".media-breakpoint .section-02 .tekst-blok").hide();
    $(
      `.media-breakpoint .section-02 .tekst-blok[data-infoitem-target=${target}]`
    ).fadeIn();
  });

  /*----------  SECTION 03 // TRANSITION RESTAURANT ITEMS  ----------*/

  $(".section-03 .restaurant-item").click(function (event) {
    event.preventDefault();

    const target = $(this).data("target");

    $(this).toggleClass("active");
    $(".section-03 .restaurant-item").toggleClass("toggled");
    $(".section-03 .restaurant-text-box").hide();
    $(`.section-03 .restaurant-text-box[data-target='${target}']`).show();
  });

  $(".section-03 .back-button").click(function (event) {
    event.preventDefault();

    $(".section-03 .restaurant-item").removeClass("toggled");
    $(".section-03 .restaurant-item").removeClass("active");
  });

  /*----------  SECTION 04 // BOEK OF BLOEM BUTTON CLICK  ----------*/

  $(".section-04 .section-04-button").click(function (event) {
    event.preventDefault();

    const target = $(this).data("target");

    $(".section-04 .section-04-button").addClass("active");
    $(".section-04 .section-04-content").removeClass("active");

    $(this).removeClass("active");
    $(`.section-04-content[data-target='${target}']`).toggleClass("active");
  });

  $(".section-04 .back-button").click(function (event) {
    event.preventDefault();

    $(".section-04 .section-04-button").addClass("active");
    $(".section-04 .section-04-content").removeClass("active");
  });

  $("#boek-kijker-toggle").click(function (event) {
    event.preventDefault();
    $("#boek-kijker-item").addClass("active");
  });

  $("#boek-kijker-item .back-button").click(function (event) {
    event.preventDefault();
    $("#boek-kijker-item").removeClass("active");
  });

  $("#bloem-kijker-toggle").click(function (event) {
    event.preventDefault();
    $("#bloem-kijker-item").addClass("active");
  });

  $("#bloem-kijker-item .back-button").click(function (event) {
    event.preventDefault();
    $("#bloem-kijker-item").removeClass("active");
  });

  /*----------  SECTION 04 // BOEK OF BLOEM MOBILE  ----------*/

  $(".boek-en-bloem a").click(function (event) {
    event.preventDefault();

    if ($(this).hasClass("boek")) {
      $(".boek-en-bloem .boek-text").fadeIn("fast");
    } else if ($(this).hasClass("bloem")) {
      $(".boek-en-bloem .bloem-text").fadeIn("fast");
    }
  });

  $(".boek-en-bloem .back").click(function (event) {
    event.preventDefault();
    $(".boek-en-bloem .section-03-text").fadeOut("fast");
  });

  /*----------  SECTION 06 // TRANSITION EVENT BLOCKS  ----------*/

  $(".section-06 .evenement-button").click(function (event) {
    event.preventDefault();

    const target = $(this).data("target");

    $(this).toggleClass("active");
    $(".section-06 .evenement-button").toggleClass("toggled");
    $(".section-06 .event-text-box").hide();
    $(`.section-06 .event-text-box[data-target='${target}']`).show();
  });

  $(".section-06 .back-button").click(function (event) {
    event.preventDefault();

    $(".section-06 .evenement-button").removeClass("toggled");
    $(".section-06 .evenement-button").removeClass("active");
  });

  /*----------  SECTION 07 // WIPE TUSSEN TWEE DIVS  ----------*/

  $(".section-07-slide").click(function (event) {
    event.preventDefault();

    if ($(this).hasClass("active")) {
      return null;
    } else {
      $(".section-07-slide").toggleClass("active");
    }
  });

  /*----------  SECTION 08 // INFO EN CONTACT FORM SUBMIT  ----------*/

  $(".section-08 button").click(function (event) {
    setInterval(function () {
      fullPageSizeCheck();
    }, 4000);

    console.log("Form submission clicked");
  });

  /**
   *
   * MOBILE / TABLET FUNCTIONS
   *
   */

  /*======  ACTIONS  ======*/

  /*----------  MOBILE MENU TOGGLE  ----------*/

  $(".offCanvasToggler").click(function (event) {
    event.preventDefault();
    $(".off-canvas-nav").toggleClass("active");
    $(".fullscreen-overlay").toggleClass("active");
    $("html, body").toggleClass("no-scrolling");
  });

  /*----------  SECTION 01 // DE TRAAGHEID  ----------*/

  $(".mobile-version .section-01-tabs .tab-header").click(function (event) {
    if ($(this).data("tab-target")) {
      event.preventDefault();
      const tabTarget = $(this).data("tab-target");
      $(".mobile-version .section-01-tabs .tab-content").slideUp();
      $(
        `.mobile-version .section-01-tabs .tab-content[data-tab-target="${tabTarget}"]`
      ).slideDown();
    }
  });

  /*----------  SECTION 02 // RESTAURANT TABS SCROLLTO ON CLICK  ----------*/

  $("#section-02-tabs li a").click(function (event) {
    // console.log("section clicked");
    // $('.mobile-version .section-02 .tab-content');

    $([document.documentElement, document.body]).animate(
      {
        scrollTop:
          $(".mobile-version .section-02 .tab-content").offset().top - 150
      },
      1500
    );
  });

  /*----------  SECTION 06 // EVENT MOBILE  ----------*/

  $(".evenementen a").click(function (event) {
    event.preventDefault();

    const target = $(this).data("target");
    $(`.section-05-text[data-target="${target}"]`).fadeIn("fast");
  });

  $(".evenementen .back").click(function (event) {
    event.preventDefault();
    $(".evenementen .section-05-text").fadeOut("fast");
  });

  /*----------  MOBILE MENU SCROLL  ----------*/

  // $(".off-canvas-nav a").click(function(event) {
  //   event.preventDefault();

  //   if ($(this).hasClass("offCanvasToggler")) {
  //     // nothing
  //   } else {
  //     const target = $(this).data("scroll-target");

  //     $(".off-canvas-nav").toggleClass("active");
  //     $(".fullscreen-overlay").toggleClass("active");
  //     $("html, body").toggleClass("no-scrolling");

  //     $([document.documentElement, document.body]).animate(
  //       {
  //         scrollTop: $(`.mobile-version .${target}`).offset().top - 113
  //       },
  //       1500
  //     );
  //   }
  // });
});
